<template>
    <div>
        <div class="guide">
            <div class="guide__title">欢迎{{ systemName }}</div>
            <div class="guide__list">
                <div
                    :class="[
                        'guide__item',
                        'guide__item--first',
                        {
                            small: step == 2,
                            'guide__item--success': status == EMTERPRISE_STATE_PASSED,
                        },
                    ]"
                >
                    <div class="guide__item-content">
                        <div
                            :class="[
                                'guide__item-title',
                                'guide__item-title--first',
                                {
                                    small: step == 2,
                                },
                            ]"
                        />
                        <div class="guide__item-bottom">
                            <div class="guide__item-bottom__left">
                                <div class="guide__item-info">
                                    <div class="guide__item-info__title">
                                        企业名称：
                                    </div>
                                    <div class="guide__item-info__content">
                                        {{ enterpriseInfo.enterpriseName || '-' }}
                                    </div>
                                </div>
                                <div class="guide__item-info">
                                    <div class="guide__item-info__title">
                                        认证状态：
                                    </div>
                                    <div class="guide__item-info__content">
                                        <i
                                            :class="[
                                                'guide__item-status',
                                                {
                                                    'guide__item-status--success':
                                                        status == EMTERPRISE_STATE_PASSED,
                                                    'guide__item-status--default':
                                                        status == EMTERPRISE_STATE_INIT,
                                                },
                                            ]"
                                        />
                                        {{ enterpriseInfo.state || '待认证' }}<span
                                            v-if="status == EMTERPRISE_STATE_NO_PASSED"
                                            class="guide__item-info__label"
                                        >已驳回</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a-button
                                    :disabled="step == 2"
                                    type="primary"
                                    @click="goEnterpriseAuth"
                                >
                                    认证管理
                                </a-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 引入订单状态枚举
import { Button as AButton } from 'ant-design-vue'

import eventMixin from '@/mixins/createPage/event'
import { goEnterpriseAuth } from '@/utils/openNewPage'

import getEnterpriseService from '@/service/getEnterpriseService'


const EMTERPRISE_STATE_INIT = -1
const EMTERPRISE_STATE_PENDING_REVIEW = 0
const EMTERPRISE_STATE_PASSED = 1
const EMTERPRISE_STATE_NO_PASSED = 2
const EMTERPRISE_STATE_CLOSE = 9


const STEP_ONE = 1

export default {
    name: 'GuidePage',
    components: {
        AButton
    },
    mixins: [eventMixin],
    data() {
        return {
            step: 1,
            enterpriseInfo: {},
            status: EMTERPRISE_STATE_INIT,
            EMTERPRISE_STATE_INIT,
            EMTERPRISE_STATE_PENDING_REVIEW,
            EMTERPRISE_STATE_PASSED,
            EMTERPRISE_STATE_NO_PASSED,
            EMTERPRISE_STATE_CLOSE,
            STEP_ONE
        }
    },
    computed: {
        systemName() {
            return this.$store.getters.systemName
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            await this.getEnterprise()
            const { enterpriseInfo = {} } = this
            const { processState } = enterpriseInfo
            this.status = processState || -1

            if (enterpriseInfo.processState === EMTERPRISE_STATE_PASSED ) {
                this.$router.push('/')
            }

            if (enterpriseInfo.processState === -1) {
                this.status = EMTERPRISE_STATE_INIT
                this.$set(this.enterpriseInfo, 'state', '未认证')
            }
        },
        async getEnterprise() {
            const enterpriseInfo = await getEnterpriseService()
            this.enterpriseInfo = enterpriseInfo
        },
        goEnterpriseAuth() {
            goEnterpriseAuth()
        }
    }
}
</script>

<style lang="scss">
.guide {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding-top: 64px;
    &__title {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #a9abad;
        line-height: 24px;
        letter-spacing: 8px;
    }
    &__list {
        position: relative;
        margin: 72px auto 0;
    }

    &__item {
        position:relative;
        margin: 0 auto;
        width: 904px;
        background: #fff;
        padding-top: 242px;
        box-shadow: 0px 1px 20px 0px rgba(220, 222, 223, 0.5),
            12px -14px 24px -12px rgba(139, 169, 204, 0.5);
        border-radius: 4px;
        border: 2px solid rgba(24, 119, 242, 0.66);
        background: url('~@/assets/images/guide/bg.jpg') no-repeat;
        background-size: 904px;

        &--first {
            left: 0;
            z-index: 1;

            &::before {
                position: absolute;
                right: 20px;
                top: -40px;
                display: block;
                content: '';
                width: 289px;
                height: 231px;
                background: url('~@/assets/images/guide/step-1.png') no-repeat;
                background-size: cover;
            }
        }
        &--second {
            right: 0;
            z-index: 1;
            &::before {
                position: absolute;
                right: 20px;
                top: -40px;
                display: block;
                content: '';
                width: 289px;
                height: 231px;
                background: url('~@/assets/images/guide/step-2.png') no-repeat;
                background-size: cover;
            }
        }

        &.small {
            top: 10px;
            padding-top: 202px;
            border-color: #bac7e1;
            box-shadow: none;
            z-index: 0;

            &::before {
                display: none;
            }
        }

        &--success::after {
            display: block;
            position: absolute;
            content: '';
            width: 94px;
            height: 94px;
            left: 202px;
            top: 130px;
            background: url('~@/assets/images/guide/success-icon.png') no-repeat;
            background-size: cover;
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
        }

        &-title {
            position: absolute;
            top: 30px;
            width: 88px;
            height: 18px;
            left: 24px;
            &--right {
                left: auto;
                right: 15px;
            }

            &--first {
                background: url('~@/assets/images/guide/title-1.png') no-repeat;
                background-size: cover;
            }

            &--second {
                background: url('~@/assets/images/guide/title-2.png') no-repeat;
                background-size: cover;
            }
        }

        &-bottom {
            padding: 17px 24px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-status {
            margin-right: 4px;
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url('~@/assets/images/guide/icon-loading.png') no-repeat;
            background-size: cover;
            &--success {
                background: url('~@/assets/images/guide/icon-success.png')
                    no-repeat;
                background-size: cover;
            }
            &--default {
                background: url('~@/assets/images/guide/icon-default.png')
                    no-repeat;
                background-size: cover;
            }
        }

        &-info {
            display: flex;
            align-items: center;
            & ~ & {
                margin-top: 12px;
            }
            &__title {
                color: #646566;
            }
            &__label {
                display: inline-block;
                margin-left: 8px;
                padding: 1px 8px;
                font-size: 12px;
                line-height: 20px;
                color: #e6125f;
                line-height: 20px;
                text-shadow: 0px 1px 20px rgba(220, 222, 223, 0.5);
                background: #fff1f0;
                box-shadow: 0px 1px 20px 0px rgba(220, 222, 223, 0.5);
                border-radius: 2px;
            }

            &__content {
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
