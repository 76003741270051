var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"guide"},[_c('div',{staticClass:"guide__title"},[_vm._v("欢迎"+_vm._s(_vm.systemName))]),_c('div',{staticClass:"guide__list"},[_c('div',{class:[
                    'guide__item',
                    'guide__item--first',
                    {
                        small: _vm.step == 2,
                        'guide__item--success': _vm.status == _vm.EMTERPRISE_STATE_PASSED,
                    },
                ]},[_c('div',{staticClass:"guide__item-content"},[_c('div',{class:[
                            'guide__item-title',
                            'guide__item-title--first',
                            {
                                small: _vm.step == 2,
                            },
                        ]}),_c('div',{staticClass:"guide__item-bottom"},[_c('div',{staticClass:"guide__item-bottom__left"},[_c('div',{staticClass:"guide__item-info"},[_c('div',{staticClass:"guide__item-info__title"},[_vm._v(" 企业名称： ")]),_c('div',{staticClass:"guide__item-info__content"},[_vm._v(" "+_vm._s(_vm.enterpriseInfo.enterpriseName || '-')+" ")])]),_c('div',{staticClass:"guide__item-info"},[_c('div',{staticClass:"guide__item-info__title"},[_vm._v(" 认证状态： ")]),_c('div',{staticClass:"guide__item-info__content"},[_c('i',{class:[
                                            'guide__item-status',
                                            {
                                                'guide__item-status--success':
                                                    _vm.status == _vm.EMTERPRISE_STATE_PASSED,
                                                'guide__item-status--default':
                                                    _vm.status == _vm.EMTERPRISE_STATE_INIT,
                                            },
                                        ]}),_vm._v(" "+_vm._s(_vm.enterpriseInfo.state || '待认证')),(_vm.status == _vm.EMTERPRISE_STATE_NO_PASSED)?_c('span',{staticClass:"guide__item-info__label"},[_vm._v("已驳回")]):_vm._e()])])]),_c('div',[_c('a-button',{attrs:{"disabled":_vm.step == 2,"type":"primary"},on:{"click":_vm.goEnterpriseAuth}},[_vm._v(" 认证管理 ")])],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }